import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Header1 from '../assets/Header1.jpg'
import Header2 from '../assets/Header2.jpg'
import Header3 from '../assets/Header3.jpg' 
import Backtotop from './Backtotop'
import About from './About';
import Contact from './Contact';
import Wheretobuy from './Wheretobuy';

function Home () {     
  return (
    <div>            
     <Backtotop/>                                              
     <Carousel autoPlay 
      transitionTime={1000} 
      interval={8000} infiniteLoop 
      showThumbs={false}  showStatus={false}>
      <div><img src={Header2} alt="" /></div>
      <div><img src={Header1} alt=""/></div>
      <div><img src={Header3} alt=""/></div> 
      </Carousel>   
      <Wheretobuy/>              
      <Contact/> 
      <About/>  
   </div>
    );
   }
 export default Home;
