import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  pagetitle:{
    fontFamily:'Poppins',
    paddingTop:'30px',
    fontSize: '42px'
  },
  pagesubtitle:{
   fontFamily: 'Poppins',
   fontSize: '18px',
   fontWeight: 'bold',
   marginBottom:'2px'
  },
  pagecontent: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    marginBottom:'10px'
  }
}));

export default function Privacy() {
  const classes = useStyles();

  return (
    <div>     
        
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" className={classes.       pagetitle} gutterBottom>
          Privacy Policy
        </Typography>        
      </Container>
      <Container maxWidth="lg" component="main">
        <Grid container>        
          <Typography className={classes.pagecontent}>          
            Protecting your private information is our priority. This Statement of Privacy applies to Henryblock.com and Sea-Land Distributors, LLC. and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Sea-Land Distributors, LLC. include Henryblock.com and Sea-Land Distributors. The Sea-Land Distributors website is a Company / Product Information and Sales site. By using the Sea-Land Distributors website, you consent to the data practices described in this statement. 
            </Typography>
          <Typography className={classes.pagesubtitle}>
           Collection of your Personal Information 
          </Typography>
          <Typography className={classes.pagecontent}>
            In order to better provide you with products and services offered on our Site, Sea-Land Distributors may collect personally identifiable information, such as your: <br/>
           </Typography>
           <Typography className={classes.pagecontent}>
           <ul>
             <li>- First and Last Name</li>
             <li>- Mailing Address</li>
             <li>- E-mail Address </li>
             <li>- Phone Number</li>
             <li>- User Questions</li>
           </ul>
           </Typography>
           <Typography className={classes.pagecontent}>
            Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Sea-Land Distributors's public message boards, this information may be collected and used by others. 
          </Typography>
          <Typography className={classes.pagecontent}>
            We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. 
          </Typography>
          <Typography className={classes.pagesubtitle}>
          Use of your Personal Information 
          </Typography>
          <Typography className={classes.pagecontent}>
          Sea-Land Distributors collects and uses your personal information to operate its website(s) and deliver the services you have requested. 
          </Typography>
          <Typography className={classes.pagecontent}>
          Sea-Land Distributors may also use your personally identifiable information to inform you of other products or services available from Sea-Land Distributors and its affiliates. 
          </Typography>
          <Typography className={classes.pagesubtitle}>
          Sharing Information with Third Parties 
          </Typography>          
          <Typography className={classes.pagecontent}>   
          Sea-Land Distributors does not sell, rent or lease its customer lists to third parties.<br></br><div style={{marginTop:'5px'}}></div>
          Sea-Land Distributors may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Sea-Land Distributors, and they are required to maintain the confidentiality of your information. 
          </Typography>
          <Typography className={classes.pagecontent}>
          Sea-Land Distributors may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Sea-Land Distributors or the site; (b) protect and defend the rights or property of Sea-Land Distributors; and/or (c) act under exigent circumstances to protect the personal safety of users of Sea-Land Distributors, or the public.  
          </Typography>
          <Typography className={classes.pagesubtitle}>
          Tracking User Behavior 
          </Typography>
          <Typography className={classes.pagecontent}>
          Sea-Land Distributors may keep track of the websites and pages our users visit within Sea-Land Distributors, in order to determine what Sea-Land Distributors services are the most popular. This data is used to deliver customized content and advertising within Sea-Land Distributors to customers whose behavior indicates that they are interested in a particular subject area. 
          </Typography>
          <Typography className={classes.pagesubtitle} style={{borderTop: '2px solid darkgrey'}}>
          Automatically Collected Information 
          </Typography>
          <Typography className={classes.pagecontent}>
            Information about your computer hardware and software may be automatically collected by Sea-Land Distributors. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Sea-Land Distributors website. 
          </Typography>
          <Typography className={classes.pagesubtitle}>            
          Use of Cookies 
          </Typography>
          <Typography className={classes.pagecontent}>
          The Sea-Land Distributors website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. 
          </Typography>
          <Typography className={classes.pagecontent}>
            One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Sea-Land Distributors pages, or register with Sea-Land Distributors site or services, a cookie helps Sea-Land Distributors to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Sea-Land Distributors website, the information you previously provided can be retrieved, so you can easily use the Sea-Land Distributors features that you customized.   
          </Typography>  
            You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Sea-Land Distributors services or websites you visit. 
          <Typography className={classes.pagesubtitle}>
          Links  
          </Typography>
          <Typography className={classes.pagecontent}>
          This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.
          </Typography>
          <Typography className={classes.pagesubtitle}>
          Security of your Personal Information 
          </Typography>
          <Typography className={classes.pagecontent}>
          Sea-Land Distributors secures your personal information from unauthorized access, use, or disclosure. Sea-Land Distributors uses the following methods for this purpose: 
          </Typography>
          <Typography className={classes.pagecontent}>
          -	SSL Protocol  
          </Typography>
          <Typography className={classes.pagecontent}>
          When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
          </Typography>
          <Typography className={classes.pagecontent}>
          We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. 
          </Typography>
          <Typography className={classes.pagesubtitle}>
          Children Under Thirteen  
          </Typography>
          <Typography className={classes.pagecontent}>            
          Sea-Land Distributors does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.
          </Typography>
          <Typography className={classes.pagesubtitle}>
          E-mail Communications 
          </Typography>
          <Typography className={classes.pagecontent}>
          From time to time, Sea-Land Distributors may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Sea-Land Distributors or click on a link therein. 
          </Typography>
          <Typography className={classes.pagecontent}>  
          If you would like to stop receiving marketing or promotional communications via email from Sea-Land Distributors, you may opt out of such communications by Clicking Unsubscribe. 
          </Typography>
          <Typography className={classes.pagesubtitle}> 
          External Data Storage Sites 
          </Typography> 
          </Grid>
          </Container>    
          <Container maxWidth="lg">  
            <Grid>
          <Typography item className={classes.pagecontent}>          
           We may store your data on servers provided by third party hosting vendors with whom we have contracted.
            </Typography>
          <Typography className={classes.pagesubtitle}> 
          Changes to this Statement 
          </Typography>
          <Typography className={classes.pagecontent}> 
            Sea-Land Distributors reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy. 
          </Typography>
          <Typography className={classes.pagesubtitle}> 
          Contact Information 
          </Typography>
          <Typography className={classes.pagecontent}>           
          Sea-Land Distributors welcomes your questions or comments regarding this Statement of Privacy. If you believe that Sea-Land Distributors has not adhered to this Statement, please contact Sea-Land Distributors at:  
          </Typography>
          </Grid>      
          <Typography className={classes.pagecontent}> 
          <ul>
            <li>Sea-Land Distributors, LLC. </li>
            <li>1000 Edwards Avenue, Suite C </li>
            <li>  New Orleans, Louisiana 70123</li>
          </ul>         
          </Typography>
          <br/>
          <Typography className={classes.pagecontent}> 
          <ul>
            <li>Email Address:  </li>
            <li>info@sldrigging.com </li>
          </ul>  
          </Typography>
          <Typography className={classes.pagecontent}> 
          <ul>
            <li> Telephone number:   </li>
            <li> 504-736-9477</li>
          </ul>  
          </Typography>
          <Typography className={classes.pagecontent}>        
           Effective as of March 27, 2020 
          </Typography>
          </Container>
      <div style={{marginBottom: '50px'}}></div>   
      </div>
  );
}