import React from 'react'
import {  Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px'
   },
   pagesubtitle:{
    paddingBottom: '40px', 
    fontFamily: 'Poppins',
    fontSize:'20px'
   },
 }));

export default function About() {
    const classes = useStyles();
    return (
        <div id="about" style={{backgroundColor: '#d6d9dd', paddingBottom: '50px'}}>    
            <Container maxWidth="md" component="main" >
                <Typography  align="center" className={classes.pagetitle} gutterBottom>
                About Us
                </Typography>   
                <Typography  className={classes.pagesubtitle}>
                Blocks are truly a working device. They rotate, spin, re-direct load, and can reduce the effort it takes to move an object. The design and construction are very important and the Henry Block & Pulley line of products have the right features – properly grooved sheaves and self-lubricated bushings just to name a couple. Small diameter cable blocks and sheaves are the first line of Henry Block & Sheave’s products to be introduced and they are now the only Made in USA cable blocks to be offered for this type and range of product. Innovative designs for new block products are being developed now!   
             </Typography>              
             </Container>   
        </div>
    )
}
