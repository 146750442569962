import React from 'react'
import JotformEmbed from 'react-jotform-embed';
import {Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './../App.css';

const useStyles = makeStyles(theme => ({
    pagetitle:{
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '50px'
    }
}));

function Contactform() {
    const classes = useStyles();

    return (
      <div>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center"className={classes.pagetitle} gutterBottom>
          CONTACT FORM
        </Typography>       
      </Container>
      <Grid container>
         <JotformEmbed src="https://form.jotform.com/200904450487959" />
      </Grid>
      <div style={{marginBottom: '50px'}}></div>            
      </div>
    )
}
export default Contactform