import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Topnav from '../components/Topnav'
import Scroll from './Scroll'


const useStyles = makeStyles(theme => ({
  root: {    
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },    
  },
  tree:{
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  },
  list : {
    width : 200,
    fontFamily: 'Poppins'
  },  
  sideBarIcon : {
    color : "white",
    cursor : "pointer",
  },
  link:
    { 
      fontFamily: 'Poppins',
      fontSize: '22px',
      paddingBottom: '3px',
      textDecoration: 'none',
      color: '#ebebe6',
      display:'flex',
      justifyContent: 'space-evenly',
      '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .3s ease',
     },   
    },
    
}));

function ElevateAppBar() {
  const classes = useStyles();
 
  return (    
    <React.Fragment>
      <Topnav/>
      <AppBar className={classes.root} position="sticky" 
      style={{ backgroundColor: '#000000', height: '55px'}}>        
        <Toolbar >
        <Typography  align="center"> 
        <Scroll/>       
          </Typography>          
          <Typography variant = "subheading"color="inherit" style={{flexGrow:1}}> 
          <Link to="/carbonsteelblocks" className={classes.link}>CARBON STEEL BLOCKS
          </Link></Typography>
          <Typography variant = "subheading" color="inherit" style={{flexGrow:1}}> 
          <Link to="/stainlesssteelblocks" className={classes.link}>STAINLESS STEEL BLOCKS</Link></Typography>
          <Typography variant = "subheading"color="inherit" style={{flexGrow:1}}> 
          <Link to="/sheaves" className={classes.link}>SHEAVES </Link></Typography> 
      </Toolbar>
      </AppBar>      
    </React.Fragment>
  );
};


export default ElevateAppBar;